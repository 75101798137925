// main.ts
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import * as Sentry from "@sentry/angular";

import { L10n, registerLicense, setCulture } from "@syncfusion/ej2-base";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { hmrBootstrap } from "./hmr";

if (environment.production) {
  enableProdMode();

  if (environment.sentryDSN.length) {
  }
}

setCulture("es-ES");
registerLicense(environment.syncFusionKey);

L10n.load({
    'es-ES': {
        'image-editor': {
            'Browse': 'Examinar',
            'Crop': 'Recortar',
            'ZoomIn': 'Acercar',
            'ZoomOut': 'Alejar',
            'Transform': 'Transformar',
            'Annotation': 'Anotación',
            'Text': 'Agregar texto',
            'Pen': 'Lápiz',
            'Reset': 'Restablecer',
            'Save': 'Guardar',
            'Select': 'Seleccionar',
            'RotateLeft': 'Girar a la izquierda',
            'RotateRight': 'Girar a la derecha',
            'HorizontalFlip': 'Voltear horizontalmente',
            'VerticalFlip': 'Voltear verticalmente',
            'OK': 'Aceptar',
            'Cancel': 'Cancelar',
            'FillColor': 'Color de relleno',
            'StrokeColor': 'Color del trazo',
            'StrokeWidth': 'Ancho del trazo',
            'FontFamily': 'Familia de fuente',
            'FontStyle': 'Estilo de fuente',
            'FontSize': 'Tamaño de fuente',
            'FontColor': 'Color de fuente',
            'Pan': 'Desplazar',
            'Move': 'Mover',
            'Custom': 'Personalizado',
            'Square': 'Cuadrado',
            'Circle': 'Círculo',
            'Rectangle': 'Rectángulo',
            'Line': 'Línea',
            'Default': 'Predeterminado',
            'Bold': 'Negrita',
            'Italic': 'Cursiva',
            'BoldItalic': 'Negrita Cursiva',
            'CropAndTransform': 'Recortar y transformar',
        }
    }
});


Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [
    // Captura de errores globales y promesas no manejadas
    Sentry.globalHandlersIntegration({
      onerror: true,
      onunhandledrejection: true,
    }),
    // Integración de rastreo de navegador
    Sentry.browserTracingIntegration({}),
    // Configuración adicional para Replay, incluyendo detalles de red
    Sentry.replayIntegration({
      networkDetailAllowUrls: [/https:\/\/apiip-h6gfe6dvfbdngzbd\.eastus2-01\.azurewebsites\.net\/api\/v1\/([A-Za-z]+(\/[A-Za-z]+)*)/i],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
    // Integración para feedback de usuario
    Sentry.feedbackIntegration({
      colorScheme: "system",
      isNameRequired: true,
      isEmailRequired: true,
      triggerLabel: "Crear un ticket",
      formTitle: "¿Cómo podemos ayudarte?",
      submitButtonLabel: "Enviar",
      cancelButtonLabel: "Cancelar",
      addScreenshotButtonLabel: "Adjuntar captura de pantalla",
      removeScreenshotButtonLabel: "Eliminar captura de pantalla",
      nameLabel: "Nombre",
      emailLabel: "Correo electrónico",
      messageLabel: "Detalle el problema",
      messagePlaceholder: "Escribe aquí tu problema",
      isRequiredLabel: "(requerido)",
      namePlaceholder: "Escribe tu nombre",
      emailPlaceholder: "Escribe tu correo electrónico",
      successMessageText: "¡Gracias por tu feedback! Hemos recibido tu mensaje y te responderemos lo antes posible.",
      confirmButtonLabel: "Aceptar",
    }),
  ],
  // Configuración para muestreo de trazas y repeticiones
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ["Unknown Error.", "Unknown Error", 'Error: Could not load "util".'],
});
Sentry.setContext("app", {
  name: "CRM Inmobiliaria Platino",
  version: "4.1.0",
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module["hot"]) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error("HMR is not enabled for webpack-dev-server!");
    console.log("Are you using the --hmr flag for ng serve?");
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
