import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://apiip-h6gfe6dvfbdngzbd.eastus2-01.azurewebsites.net/api/v1/",
  googleMapskey: "AIzaSyDaXyX_LvkVMSM0xId6uJIwqfu-Euw-gYA",
  sentryDSN: "https://a61166c8bcae490bada845a8faca3ecc@o4505172191608832.ingest.sentry.io/4505211440922624",
  googleAnalyticsId: "G-L3MGDSM9ET",
  syncFusionKey: "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5UdkRiW35fdHRSQmlV",
};
