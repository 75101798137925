import AdminNav from '#utils/nav-role/nav-admin';
import CommunityManagerNav from '#utils/nav-role/nav-community-manager';
import ExternalAuditNav from '#utils/nav-role/nav-external-audit';
import ManagerNav from '#utils/nav-role/nav-manager';
import SalesAdvisorNav from '#utils/nav-role/nav-sales-advisor';
/* tslint:disable:max-line-length */
import SuperAdminNav from '#utils/nav-role/nav-super-admin';

export default function defaultNavigation() {
    const isSuperAdmin = localStorage.getItem("rol") === "SuperAdmin";
    const isManager = localStorage.getItem("rol") === "Manager";
    const isCommunityManager = localStorage.getItem("rol") === "CommunityManager";
    const isSalesAdvisor = localStorage.getItem("rol") === "SalesAdvisor";
    const isCommercialSpecialist = localStorage.getItem("rol") === "CommercialSpecialist";
    const isAdmin = localStorage.getItem("rol") === "Admin";
    const isExternalAudit = localStorage.getItem('fullName') === 'Auditoría Externa';

    if (isExternalAudit) {
        return ExternalAuditNav();
    }

    if (isSuperAdmin) {
        return SuperAdminNav();
    }

    if (isSalesAdvisor) {
        return SalesAdvisorNav();
    }

    if (isManager) {
        return ManagerNav();
    }

    if (isCommunityManager) {
        return CommunityManagerNav();
    }

    if(isCommercialSpecialist){
        return AdminNav();
    }

    if (isAdmin) {
        return AdminNav();
    }
}