import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { NavigationService } from '#root/core/navigation/navigation.service';
import {
  forkJoin,
  Observable,
  of,
} from 'rxjs';
import isExternalAudit from '#utils/is-external-audit/is-external-audit';
import { CanActivate } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._navigationService.get(),
    ]);
  }
}



@Injectable({
  providedIn: 'root',
})
export class ExternalAuditGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const isAudit = isExternalAudit();
    const targetUrl = '/crm/report/payments-history';

    if (isAudit && state.url !== targetUrl) { 
      this.router.navigate([targetUrl]);
      return false; 
    }

    return true; 
  }
}

