import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/browser';

import { LoginUser } from '#interfaces/auth/login-user.interface';
import { SessionUser } from '#interfaces/auth/session-user.interface';
import { LogoutResponse } from '#interfaces/http-response/responses/http-responses';
import { AuthUtils } from '#root/core/auth/auth.utils';
import { UserStorageService } from '#root/core/user/user.service';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

import { DashboardService } from '#services/dashboard/dashboard.service';
import { User } from '../user/user.types';

@Injectable()
export class AuthService {
	private _authenticated: boolean = false;
	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _userService: UserStorageService,
		private headerService: HeadersHttpService,
		private _router: Router,
		private dashboardService: DashboardService,
	) { }
	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------
	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem('accessToken', btoa(token));
	}

	get accessToken(): string {
		let token: string = localStorage.getItem('accessToken');
		if (token != null && token != '') {
			return atob(token);
		} else {
			return '';
		}
	}
	set session(data: SessionUser) {
		let avatarUrl: string;
		if (data.photo != null && data.photo != 'string') {
			avatarUrl = data.photo;
		} else {
			avatarUrl = 'https://defc.ulpgc.es/wp-content/themes/defc-child/images/avatar.jpg';
		}
		const userDetails: User = {
			id: data.id,
			name: data.fullName,
			email: data.email,
			avatar: avatarUrl,
			status: 'Logueado'
		};
		sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
		localStorage.setItem('idUser', String(btoa(data.id)));
		localStorage.setItem('userName', String(data.userName));
		localStorage.setItem('rol', data.role);
		localStorage.setItem('email', data.email);
		localStorage.setItem('idProject', String(btoa(data.projects.toString())));
		localStorage.setItem('expirationDate', `${data.expirationDate}`);
		localStorage.setItem('fullName', data.fullName);
		localStorage.setItem('mainProjectId', data.mainProjectId.toString());
		localStorage.setItem('signature', data.signature);
		localStorage.setItem('projects', JSON.stringify(data.projects));
		this.dashboardService.SetCurrentProject(data.mainProjectId);

		if (data.photo != null) {
			localStorage.setItem('avatar', data.photo);
		} else {
			localStorage.setItem('avatar', 'https://defc.ulpgc.es/wp-content/themes/defc-child/images/avatar.jpg');
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Observable<any> {
		const baseUrl: string = environment.baseUrl + 'User/RecoveryCode';
		const response = this._httpClient.post(baseUrl, { email: email }, { headers: this.headerService.getHeadersPublic() }).pipe(
			switchMap((resp) => {
				return of(resp);
			}),
			catchError((err) => {
				return throwError(() => err as HttpErrorResponse);
			})
		);
		return response;
	}
	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		const userId: string = atob(sessionStorage.getItem('di'));
		const baseUrl: string = `${environment.baseUrl}User/ForgotPassword/${userId}`;
		const edoc: string = atob(sessionStorage.getItem('edoc'));
		const response = this._httpClient.put(baseUrl, { id: userId, password: password, code: edoc });
		return response;
	}

	updatePassword(password: string): Observable<any> {
		const userId: string = atob(localStorage.getItem('idUser'));
		const baseUrl: string = `${environment.baseUrl}User/ChangePassword/${userId}`;
		const response = this._httpClient.put(baseUrl, { id: userId, password: password }, { headers: this.headerService.getHeadersPrivate() });
		return response;
	}

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signIn(credentials: { email: string; password: string }): Observable<any> {
		const baseUrl: string = environment.baseUrl + 'User/Login';
		const requiredLogin: LoginUser = {
			email: credentials.email,
			password: credentials.password,
			userName: null,
			ipAddress: null,
			lat: null,
			lng: null,
			deviceInfo: null
		};
		// Throw error, if the user is already logged in
		if (this._authenticated) {
			return throwError('User is already logged in.');
		}

		const response = this._httpClient.post(baseUrl, requiredLogin).pipe(
			switchMap((response: any) => {
				// Store the access token in the local storage
				this.accessToken = response.data.token;
				// Set the authenticated flag to true
				this._authenticated = true;
				//Guardar Usuario con los servicios propios
				this.session = response.data;
				// Store the user on the user service
				this._userService.user = {
					id: response.id,
					name: response.data.fullName,
					email: response.data.email,
					avatar:
						response.data.photo == null ? 'https://defc.ulpgc.es/wp-content/themes/defc-child/images/avatar.jpg' : response.data.photo,
					status: 'Logueado'
				};

				// Save user Sentry
				Sentry.setUser({ id: response.data.id, email: response.data.email, username: response.data.fullName });

				// Return a new observable with the response
				return of(response);
			})
		);
		return response;
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<boolean> {
		// Sign in using the token
		const token = atob(localStorage.getItem('accessToken'));
		if (token.length > 3) {
			this._authenticated = true;
			this._userService.user = {
				id: atob(localStorage.getItem('idUser')),
				name: localStorage.getItem('fullName'),
				email: localStorage.getItem('email'),
				avatar: localStorage.getItem('avatar'),
				status: 'Logueado'
			};
			return of(true);
		} else {
			return of(false);
		}
	}
	/**
	 * Sign out
	 */
	signOut(): Observable<any> {
		// Save user Sentry
		Sentry.setUser(null);

		const sesiongStorage = AuthUtils.isTokenExpired(this.accessToken);
		if (sesiongStorage) {
			const idUser: string = atob(localStorage.getItem('idUser'));
			const baseUrl: string = `${environment.baseUrl}User/Logout/${idUser}`;
			localStorage.removeItem('accessToken');
			localStorage.removeItem('idUser');
			localStorage.removeItem('userName');
			localStorage.removeItem('email');
			localStorage.removeItem('rol');
			localStorage.removeItem('idProject');
			localStorage.removeItem('expirationDate');
			localStorage.removeItem('fullName');
			localStorage.removeItem('avatar');
			localStorage.removeItem('mainProjectId');
			localStorage.removeItem('selectedProjectId');
			sessionStorage.removeItem('userDetails');
			const response = this._httpClient.put<LogoutResponse>(baseUrl, { headers: this.headerService.getHeadersPublic() }).pipe(
				switchMap((response: LogoutResponse) => {
					// Set the authenticated flag to false
					this._authenticated = false;
					this._router.navigate(['/sign-out']);
					return of(true);
				}),
				catchError((err) => {
					this._authenticated = false;
					return of(true);
				})
			);
			return response;
		} else {
			const idUser: string = atob(localStorage.getItem('idUser'));
			const baseUrl: string = `${environment.baseUrl}User/Logout/${idUser}`;
			localStorage.removeItem('accessToken');
			localStorage.removeItem('idUser');
			localStorage.removeItem('userName');
			localStorage.removeItem('email');
			localStorage.removeItem('rol');
			localStorage.removeItem('expirationDate');
			localStorage.removeItem('fullName');
			localStorage.removeItem('avatar');
			localStorage.removeItem('idProject');
			localStorage.removeItem('mainProjectId');
			localStorage.removeItem('selectedProjectId');
			sessionStorage.removeItem('userDetails');
			const response = this._httpClient.put<LogoutResponse>(baseUrl, { headers: this.headerService.getHeadersPublic() }).pipe(
				switchMap((response: LogoutResponse) => {
					// Set the authenticated flag to false
					this._authenticated = false;
					this._router.navigate(['/sign-out']);
					return of(response);
				}),
				catchError((err) => {
					this._authenticated = false;
					return of(true);
				})
			);
			return response;
		}
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
		return this._httpClient.post('api/auth/sign-up', user);
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: { email: string; password: string }): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	/**
	 * Check the authentication status
	 */
	check(): Observable<boolean> {
		// Check if the user is logged in
		if (this._authenticated) {
			return of(true);
		}
		// Check the access token availability
		if (!this.accessToken) {
			return of(false);
		}
		// Check the access token expire date
		if (AuthUtils.isTokenExpired(this.accessToken)) {
			return of(false);
		}
		// If the access token exists and it didn't expire, sign in using it
		return this.signInUsingToken();
	}
}
