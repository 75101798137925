import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  EmptyLayoutComponent,
} from '#root/core/layout/layouts/empty/empty.component';
import { SharedModule } from '#root/shared/shared.module';

import { FuseLoadingBarModule } from '@fuse/components/loading-bar';

@NgModule({
  declarations: [EmptyLayoutComponent],
  imports: [RouterModule, FuseLoadingBarModule, SharedModule],
  exports: [EmptyLayoutComponent],
})
export class EmptyLayoutModule { }
