import { FuseNavigationItem } from "@fuse/components/navigation";

export default function ExternalAuditNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "reports",
          title: "Reportes",
          type: "collapsable",
          icon: "heroicons_outline:folder-open",
          children: [
            {
              id: "report.payments-history",
              title: "Historial de pagos",
              type: "basic",
              link: "/crm/report/payments-history",
            },
          ],
        },
      ],
    },
  ];

  return nav;
}
