import { FuseNavigationItem } from "@fuse/components/navigation";

export default function CommunityManagerNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "dashboard-v2",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },
        {
          id: "crm.messages",
          title: "Mensajes",
          type: "basic",
          icon: "heroicons_outline:inbox-in",
          link: "/crm/messages",
        },
        {
          id: "crm.adCampaign",
          title: "Campañas Publicitarias",
          type: "basic",
          icon: "heroicons_outline:megaphone",
          link: "/crm/adCampaign",
        },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los inmuebles y proveedores",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Inmuebles",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },
        {
          id: "inventory.categoryProducts.category",
          title: "Categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/categories",
        },
        {
          id: "inventory.categoryProducts.subcategory",
          title: "Sub categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/subcategories",
        },
        {
          id: "inventory.characteristic",
          title: "Caracteristicas",
          type: "basic",
          icon: "heroicons_outline:search-circle",
          link: "/inventory/characteristics",
        },
      ],
    },
  ];

  return nav;
}
