import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthService } from '#root/core/auth/auth.service';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthService
    ]
})
export class AuthModule {
}
