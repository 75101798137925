import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
        <div class="flex flex-col space-y-2 items-center justify-center h-full w-full">
            <mat-spinner *ngIf="showSpinner"></mat-spinner>
            <span  class="text-center font-bold text-2xl">
                <span *ngIf="!data?.label">
                Procesando datos...
                </span>
                <span *ngIf="data?.label">
                {{ data.label }}
                </span>
            </span>
            <small class="text-center">Espere un momento por favor</small>
        </div>
    `
})
export class LoadingDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef < LoadingDialogComponent >,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    showSpinner: boolean = true;

    ngOnInit() { 
        if(this.data?.showSpinner === false){
            this.showSpinner = this.data.showSpinner;
        }
    }
}
