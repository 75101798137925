<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.avatar" [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'
                        }"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none space-y-1">
            <span class="mt text-md font-medium">{{user.name}}</span>
            <span class="mt text-md font-medium text-gray-400">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="navigateToUserSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Perfil</span>
    </button>
    <button mat-menu-item *ngIf="rol === 'Manager'" (click)="settings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Configuraciones</span>
    </button>
       <button
        *ngIf="config?.scheme === 'dark'"
        mat-menu-item 
        (click)="setScheme('light')"
      >
        <mat-icon>light_mode</mat-icon>
        Modo claro
      </button>
      <button
        *ngIf="config?.scheme === 'light'"
        mat-menu-item 
        (click)="setScheme('dark')"
      >
        <mat-icon>dark_mode</mat-icon>
        Modo oscuro
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Cerrar Sesión</span>
    </button>
</mat-menu>