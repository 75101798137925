import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AdvisorGoalMetric } from "#interfaces/dashboard/advisor-goal-metric.interface";
import {
  IDashboardCampaignEffectiveness,
  IDashboardCampaignRoi,
} from "#interfaces/dashboard/campaign.interface";
import { CategorySalesMetric } from "#interfaces/dashboard/category-sales-metric.interface";
import { ClientsPerProject } from "#interfaces/dashboard/clients-per-project.interface";
import { ICommissionCenter } from "#interfaces/dashboard/commission-center.interface";
import { IDashboardInteriorTypeProduct } from "#interfaces/dashboard/interior-type-metric.interface";
import { LeadsKpiData } from "#interfaces/dashboard/leads-kpi.interface";
import { LostOpportunityMetric } from "#interfaces/dashboard/lost-opportunity-metric.interface";
import { NumSalesAdvisor } from "#interfaces/dashboard/num-sales-advisor.interface";
import { OpportunityAdvisor } from "#interfaces/dashboard/opportunity-advisor.interface";
import { OpportunityDeparmentMetric } from "#interfaces/dashboard/opportunity-deparment-metric.interface";
import {
  OpportunitiesKpiParams,
  OpportunityKpiMetric, PaymentRequestKPI,
  SalesPerMonthMetric, SalesPerMonthWeekMetric,
} from "#interfaces/dashboard/opportunity-kpi.interface";
import { OpportunityReasonMetric } from "#interfaces/dashboard/opportunity-reason-metric.interface";
import { OportunityResumeMetric } from "#interfaces/dashboard/opportunity-resume-metric.interface";
import { OpportunityWinLossReasonMetric } from "#interfaces/dashboard/opportunity-resume-reason-metric.interface";
import { OpportunityStepMetric } from "#interfaces/dashboard/opportunity-step-metric.interface";
import { PendingActivities } from "#interfaces/dashboard/pending-activities.interface";
import { ProducStatusData } from "#interfaces/dashboard/product-status-kpi.interface";
import { ProductYearSalesMetric } from "#interfaces/dashboard/product-year-sales-metric.interface";
import { BarChartMetric } from "#interfaces/dashboard/receipt-bank-metric.interface";
import { SalesByAdvisor } from "#interfaces/dashboard/sales-by-advisor.interface";
import { ITotalComparativeKpi } from "#interfaces/dashboard/total-comparative-kpi.interface";
import {
  AdvisorGoalMetricResponse,
  ApiResponseData,
  BankTypeProductMetricResponse,
  CategorySalesMetricResponse,
  GoalMetricsResponse,
  InteriorTypeProductMetricResponse,
  LostOpportunityMetricResponse,
  NumSalesAdvisorResponse,
  OpportunitiesResponse,
  OpportunityAdvisorResponse,
  OpportunityDeparmentMetricResponse,
  OpportunityReasonMetricsResponse,
  OpportunityResumeMetricResponse,
  OpportunityStepMetricResponse,
  PendingActivitiesResponse,
  ProductYearSalesMetricResponse,
  SalesByAdvisorResponse,
  WinLossReasonsMetricResponse,
} from "#interfaces/http-response/responses/http-responses";
import { Opportunity } from "#interfaces/opportunity/opportunity.interface";
import { HeadersHttpService } from "#utils/http-headers/http-headers.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, catchError, tap, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  // Projects Selected
  private _currentProject: BehaviorSubject<number> = new BehaviorSubject(null);
  private _goalMetricProject: BehaviorSubject<GoalMetricsResponse | null> =
    new BehaviorSubject(null);
    public readonly storageKey = 'TotalComparativeKpi';
    public readonly cacheDuration = 10 * 60 * 1000; // 10 minutes in milliseconds
  

  //Opportunity
  private _opportunityStepMetric: BehaviorSubject<
    OpportunityStepMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityFunnelStepMetric: BehaviorSubject<
    OpportunityStepMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityLossReasonMetric: BehaviorSubject<
    OpportunityReasonMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityWinReasonMetric: BehaviorSubject<
    OpportunityReasonMetric[] | null
  > = new BehaviorSubject(null);
  private _topOpportunitiesMetric: BehaviorSubject<Opportunity[] | null> =
    new BehaviorSubject(null);
  private _lostOpportunityMetric: BehaviorSubject<
    LostOpportunityMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityOriginMetric: BehaviorSubject<
    OpportunityReasonMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityResumeMetric: BehaviorSubject<
    OportunityResumeMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityDeparmentMetric: BehaviorSubject<
    OpportunityDeparmentMetric[] | null
  > = new BehaviorSubject(null);
  private _opportunityWinLossReasonResumeMetric: BehaviorSubject<
    OpportunityWinLossReasonMetric[] | null
  > = new BehaviorSubject(null);
  private _salesPerMonthMetric: BehaviorSubject<
  ApiResponseData<{ salesPerMonth: SalesPerMonthMetric[]; salesPerWeek: SalesPerMonthWeekMetric[]}> | null
  > = new BehaviorSubject(null);
  private _paymentRequestKPI: BehaviorSubject<
      ApiResponseData<PaymentRequestKPI> | null
  > = new BehaviorSubject(null);
  private _clientsPerProject: BehaviorSubject<
    ApiResponseData<ClientsPerProject[]> | null
  > = new BehaviorSubject(null);
  private _commissionCenter: BehaviorSubject<
  ApiResponseData<ICommissionCenter> | null
> = new BehaviorSubject(null);

  //Advisors
  private _pendingActivitiesMetric: BehaviorSubject<
    PendingActivities[] | null
  > = new BehaviorSubject(null);
  private _advisorGoalMetric: BehaviorSubject<AdvisorGoalMetric[] | null> =
    new BehaviorSubject(null);
  private _salesByAdvisor: BehaviorSubject<SalesByAdvisor[] | null> =
    new BehaviorSubject(null);
  private _numSalesByAdvisor: BehaviorSubject<NumSalesAdvisor[] | null> =
    new BehaviorSubject(null);
  private _opportunityAdvisor: BehaviorSubject<OpportunityAdvisor[] | null> =
    new BehaviorSubject(null);

  //Products
  private _productYearSalesMetric: BehaviorSubject<
    ProductYearSalesMetric[] | null
  > = new BehaviorSubject(null);
  private _categorySalesMetric: BehaviorSubject<CategorySalesMetric[] | null> =
    new BehaviorSubject(null);
  private _categoryNumProductSoldMetric: BehaviorSubject<
    CategorySalesMetric[] | null
  > = new BehaviorSubject(null);
  private _interiorTypeProductMetric: BehaviorSubject<IDashboardInteriorTypeProduct | null> =
    new BehaviorSubject(null);
  private _paymentTypeMetric: BehaviorSubject<IDashboardInteriorTypeProduct | null> =
    new BehaviorSubject(null);
  private _bankTypeMetric: BehaviorSubject<BarChartMetric | null> =
    new BehaviorSubject(null);

  // Meta Stats
  private _campaignRoiMetric: BehaviorSubject<IDashboardCampaignRoi | null> =
    new BehaviorSubject(null);
  private _campaignEffectivenessMetric: BehaviorSubject<IDashboardCampaignEffectiveness | null> =
    new BehaviorSubject(null);

  // KPIS

  private _opportunitiesKpiMetrics: BehaviorSubject<
    ApiResponseData<OpportunityKpiMetric>
  > | null = new BehaviorSubject(null);

  private _productStatusKpi: BehaviorSubject<
    ApiResponseData<ProducStatusData | null>
  > = new BehaviorSubject(null);

  private _leadsKpi: BehaviorSubject<ApiResponseData<LeadsKpiData | null>> =
    new BehaviorSubject(null);

  private _totalComparativeKpi: BehaviorSubject<
    ApiResponseData<ITotalComparativeKpi | null>
  > = new BehaviorSubject(null);

  private baseUrl: string = environment.baseUrl + "Dashboard/";

  constructor(
    private httpClient: HttpClient,
    private httpHeaders: HeadersHttpService
  ) { }

  // getters
  get clientsPerProject$(): Observable<ApiResponseData<ClientsPerProject[]>> {
    return this._clientsPerProject.asObservable();
  }

  get currenProjectId() {
    const advisorProjectId = Buffer.from(
      localStorage.getItem("idProject") ?? "",
      "base64"
    ).toString();
    const projectIdPersistId = Number(
      localStorage.getItem("selectedProjectId")
    );
    const adminProject = projectIdPersistId;

    const isAdmin =
      localStorage.getItem("rol") === "SuperAdmin" ||
        localStorage.getItem("rol") === "Manager" ||
        localStorage.getItem("rol") === "SalesAdvisor" ||
        localStorage.getItem("rol") === "CommercialSpecialist" ||
      localStorage.getItem("rol") === "Admin";
    if (isAdmin) {
      localStorage.setItem("selectedProjectId", adminProject.toString());
      return adminProject;
    }

    return Number(advisorProjectId.split(",")[0]);
  }

  get campaignEffectivenessMetric$(): Observable<IDashboardCampaignEffectiveness> {
    return this._campaignEffectivenessMetric.asObservable();
  }

  get campaignRoiMetric$(): Observable<IDashboardCampaignRoi> {
    return this._campaignRoiMetric.asObservable();
  }

  get opportunityDeparmentMetric$(): Observable<OpportunityDeparmentMetric[]> {
    return this._opportunityDeparmentMetric.asObservable();
  }

  get goaltProject$(): Observable<GoalMetricsResponse> {
    return this._goalMetricProject.asObservable();
  }

  get currentProject$(): Observable<number> {
    return this._currentProject.asObservable();
  }

  get opportunityAdvisor$(): Observable<OpportunityAdvisor[]> {
    return this._opportunityAdvisor.asObservable();
  }

  get salesByAdvisor$(): Observable<SalesByAdvisor[]> {
    return this._salesByAdvisor.asObservable();
  }

  get numSalesByAdvisor$(): Observable<NumSalesAdvisor[]> {
    return this._numSalesByAdvisor.asObservable();
  }

  get opportunityStepMetric$(): Observable<OpportunityStepMetric[]> {
    return this._opportunityStepMetric.asObservable();
  }

  get opportunityFunnelStepMetric$(): Observable<OpportunityStepMetric[]> {
    return this._opportunityFunnelStepMetric.asObservable();
  }

  get opportunityLossReasonMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityLossReasonMetric.asObservable();
  }

  get opportunityWinReasonMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityWinReasonMetric.asObservable();
  }

  get topOpportunitiesMetric$(): Observable<Opportunity[]> {
    return this._topOpportunitiesMetric.asObservable();
  }

  get lostOpportunityMetric$(): Observable<LostOpportunityMetric[]> {
    return this._lostOpportunityMetric.asObservable();
  }

  get opportunityOriginMetric$(): Observable<OpportunityReasonMetric[]> {
    return this._opportunityOriginMetric.asObservable();
  }

  get opportunityResumeMetric$(): Observable<OportunityResumeMetric[]> {
    return this._opportunityResumeMetric.asObservable();
  }

  get pendingActivitiesMetric$(): Observable<PendingActivities[]> {
    return this._pendingActivitiesMetric.asObservable();
  }

  get advisorGoalMetric$(): Observable<AdvisorGoalMetric[]> {
    return this._advisorGoalMetric.asObservable();
  }

  get productYearSalesMetric$(): Observable<ProductYearSalesMetric[]> {
    return this._productYearSalesMetric.asObservable();
  }

  get categorySalesMetric$(): Observable<CategorySalesMetric[]> {
    return this._categorySalesMetric.asObservable();
  }

  get categoryNumProductSoldMetric$(): Observable<CategorySalesMetric[]> {
    return this._categoryNumProductSoldMetric.asObservable();
  }

  get winLossReasonResumeMetric$(): Observable<
    OpportunityWinLossReasonMetric[]
  > {
    return this._opportunityWinLossReasonResumeMetric.asObservable();
  }

  get interiorTypeProductMetric$(): Observable<IDashboardInteriorTypeProduct> {
    return this._interiorTypeProductMetric.asObservable();
  }

  get paymentType$(): Observable<IDashboardInteriorTypeProduct> {
    return this._paymentTypeMetric.asObservable();
  }

  get bankType$(): Observable<BarChartMetric> {
    return this._bankTypeMetric.asObservable();
  }

  get opportunitiesKpiMetrics$(): Observable<
    ApiResponseData<OpportunityKpiMetric>
  > {
    return this._opportunitiesKpiMetrics.asObservable();
  }

  get productStatusKpi$(): Observable<ApiResponseData<ProducStatusData>> {
    return this._productStatusKpi;
  }

  get leadsKpi$(): Observable<ApiResponseData<LeadsKpiData>> {
    return this._leadsKpi.asObservable();
  }

  get paymentRequestKpi$(): Observable<ApiResponseData<PaymentRequestKPI>> {
    return this._paymentRequestKPI.asObservable();
  }

  get totalComparativeKpi$(): Observable<
    ApiResponseData<ITotalComparativeKpi>
  > {
    return this._totalComparativeKpi.asObservable();
  }

  get salesPerMonthMetric$(): Observable<ApiResponseData<{ salesPerMonth: SalesPerMonthMetric[]; salesPerWeek: SalesPerMonthWeekMetric[]}>> {
    return this._salesPerMonthMetric.asObservable();
  }

  get commissionCenter$(): Observable<ApiResponseData<ICommissionCenter>> {
    return this._commissionCenter.asObservable();
  }

  SetCurrentProject(id: number) {
    this._currentProject.next(id);
    localStorage.setItem('selectedProjectId', id.toString());
    this._currentProject.next(null);
  }

  paymentRequestKPI(projectId: number): Observable<ApiResponseData<PaymentRequestKPI>> {
    return this.httpClient
        .get<ApiResponseData<PaymentRequestKPI>>(this.baseUrl + "PaymentRequestKPI", {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { ProjectId: projectId },
        })
        .pipe(
            tap((res) => {
              this._paymentRequestKPI.next({
                ...res,
                data: {
                  ...res.data,
                  resolutionTime: res.data.resolutionTime.split(':').map((time) => parseInt(time).toLocaleString('es-HN', { maximumFractionDigits: 0 }).padStart(2, '0')).join(':'),
                }
              });
            }),
            catchError((err) => {
              return throwError(() => err as HttpErrorResponse);
            })
        );
  }

  receiptBankMetrics(year: number): Observable<BankTypeProductMetricResponse> {
    return this.httpClient
      .get<BankTypeProductMetricResponse>(this.baseUrl + "ReceiptBankMetrics", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { year },
      })
      .pipe(
        tap((res) => {
          this._bankTypeMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  paymentTypeMetrics(
    year: number
  ): Observable<InteriorTypeProductMetricResponse> {
    return this.httpClient
      .get<InteriorTypeProductMetricResponse>(
        this.baseUrl + "PaymentTypeMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { year },
        }
      )
      .pipe(
        tap((res) => {
          this._paymentTypeMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  interiorTypeProductMetric(
    year: number
  ): Observable<InteriorTypeProductMetricResponse> {
    return this.httpClient
      .get<InteriorTypeProductMetricResponse>(
        this.baseUrl + "InteriorTypeMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { year },
        }
      )
      .pipe(
        tap((res) => {
          this._interiorTypeProductMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesDeparmentMetric(
    id: number
  ): Observable<OpportunityDeparmentMetricResponse> {
    return this.httpClient
      .get<OpportunityDeparmentMetricResponse>(
        this.baseUrl + "DepartmentOpportunityMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { ProjectId: id },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityDeparmentMetric.next(
            res.data.filter(({ total }) => total > 0)
          );
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesWinLossResumeMetric(
    id: number
  ): Observable<WinLossReasonsMetricResponse> {
    return this.httpClient
      .get<WinLossReasonsMetricResponse>(this.baseUrl + "ReasonWinAndLoss", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ProjectId: id },
      })
      .pipe(
        tap((res) => {
          this._opportunityWinLossReasonResumeMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesResumeMetric(
    id: number
  ): Observable<OpportunityResumeMetricResponse> {
    return this.httpClient
      .get<OpportunityResumeMetricResponse>(this.baseUrl + "WinAndLoss", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ProjectId: id },
      })
      .pipe(
        tap((res) => {
          this._opportunityResumeMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesByAdvisor(id: number): Observable<OpportunityAdvisorResponse> {
    return this.httpClient
      .get<OpportunityAdvisorResponse>(
        this.baseUrl + "OpportunitiesByAdvisor",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { ProjectId: id },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityAdvisor.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunitiesKPI(
    params: OpportunitiesKpiParams
  ): Observable<ApiResponseData<OpportunityKpiMetric>> {
    const url = `${this.baseUrl}OpportunitiesKPI`;
    return this.httpClient
      .get<ApiResponseData<OpportunityKpiMetric>>(url, {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ...params },
      })
      .pipe(
        tap((resp) => {
          this._opportunitiesKpiMetrics.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  TotalComparativeKpi(params: OpportunitiesKpiParams): Observable<ApiResponseData<ITotalComparativeKpi>> {
    const url = `${this.baseUrl}TotalComparativeKPI`;
    // const storedData = this.getStoredData(params);

    // if (storedData) {
    //   this._totalComparativeKpi.next(storedData);
    //   return of(storedData);
    // }

    return this.httpClient
      .get<ApiResponseData<ITotalComparativeKpi>>(url, {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ...params },
      })
      .pipe(
        tap((resp) => {
          this.storeData(resp, params);
          this._totalComparativeKpi.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  private storeData(data: ApiResponseData<ITotalComparativeKpi>, params: OpportunitiesKpiParams): void {
    const storedObject = {
        data,
        expiry: new Date().getTime() + this.cacheDuration,
    };
    const paramsToStore: any = {}
    if(params.projectId) paramsToStore.projectId = params.projectId;

    localStorage.setItem(`${this.storageKey}${params?.projectId?.toString()}`, JSON.stringify(storedObject));
}

private getStoredData(params: OpportunitiesKpiParams): ApiResponseData<ITotalComparativeKpi> | null {
    const paramsCopy: any = {};
    paramsCopy.projectId = params.projectId;

    const storedObjectStr = localStorage.getItem(`${this.storageKey}${paramsCopy?.projectId?.toString()}`);
    const storedObject = JSON.parse(storedObjectStr);


    if (!storedObjectStr) {
        return null;
    }

    const now = new Date().getTime();

    if (now > storedObject.expiry) {
        localStorage.removeItem(this.storageKey);
        return null;
    }

    return storedObject?.data as ApiResponseData<ITotalComparativeKpi>;
}

  ProductStatusKPI(params: {
    projectId: number;
  }): Observable<ApiResponseData<ProducStatusData>> {
    const url = `${this.baseUrl}ProductStatusKPI`;
    return this.httpClient
      .get<ApiResponseData<ProducStatusData>>(url, {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ...params },
      })
      .pipe(
        tap((resp) => {
          this._productStatusKpi.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  LeadsKpi(
    params: OpportunitiesKpiParams
  ): Observable<ApiResponseData<LeadsKpiData>> {
    const url = `${this.baseUrl}LeadsKPI`;
    return this.httpClient
      .get<ApiResponseData<LeadsKpiData>>(url, {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ...params },
      })
      .pipe(
        tap((resp) => {
          this._leadsKpi.next(resp);
        })
      );
  }

  TopOpportunitiesMetric(projectId: number): Observable<OpportunitiesResponse> {
    return this.httpClient
      .get<OpportunitiesResponse>(
        this.baseUrl + "TopActiveOpportunitiesMetric",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._topOpportunitiesMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityStepMetrics(
    start: string,
    end: string,
    projectId: number
  ): Observable<OpportunityStepMetricResponse> {
    return this.httpClient
      .get<OpportunityStepMetricResponse>(
        this.baseUrl + "OpportunityStepMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { startDate: start, endDate: end, ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityStepMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityFunnelStepMetrics(
    start: string,
    end: string,
    projectId: number
  ): Observable<OpportunityStepMetricResponse> {
    return this.httpClient
      .get<OpportunityStepMetricResponse>(
        this.baseUrl + "OpportunityFunnelStepMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { startDate: start, endDate: end, ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityFunnelStepMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityLossReasonMetrics(
    start: string,
    end: string,
    projectId: number
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient
      .get<OpportunityReasonMetricsResponse>(
        this.baseUrl + "OpportunityLossReasonMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { startDate: start, endDate: end, ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityLossReasonMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityWinReasonMetrics(
    start: string,
    end: string,
    projectId: number
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient
      .get<OpportunityReasonMetricsResponse>(
        this.baseUrl + "OpportunityWinReasonMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { startDate: start, endDate: end, ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityWinReasonMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  LostOpportunityMetrics(
    year: number,
    projectId: number
  ): Observable<LostOpportunityMetricResponse> {
    return this.httpClient
      .get<LostOpportunityMetricResponse>(
        this.baseUrl + "LostOpportunitiesByYear",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { Year: year, ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._lostOpportunityMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  OpportunityOriginMetrics(
    projectId: number
  ): Observable<OpportunityReasonMetricsResponse> {
    return this.httpClient
      .get<OpportunityReasonMetricsResponse>(
        this.baseUrl + "OpportunityOriginMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { ProjectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._opportunityOriginMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  NumSalesByAdvisor(
    year: number,
    projectId: number
  ): Observable<NumSalesAdvisorResponse> {
    return this.httpClient
      .get<NumSalesAdvisorResponse>(this.baseUrl + "NumSalesFromYear", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { Year: year, ProjectId: projectId },
      })
      .pipe(
        tap((res) => {
          this._numSalesByAdvisor.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  SalesByAdvisor(
    year: number,
    projectId: number
  ): Observable<SalesByAdvisorResponse> {
    return this.httpClient
      .get<SalesByAdvisorResponse>(this.baseUrl + "SalesFromYear", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { Year: year, ProjectId: projectId },
      })
      .pipe(
        tap((res) => {
          this._salesByAdvisor.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  AdvisorPendingActivities(
    projectId: number
  ): Observable<PendingActivitiesResponse> {
    return this.httpClient
      .get<PendingActivitiesResponse>(
        this.baseUrl + "AdvisorPendingActivities",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: { projectId: projectId },
        }
      )
      .pipe(
        tap((res) => {
          this._pendingActivitiesMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  AdvisorGoalMetrics(
    date: string,
    projectId: number
  ): Observable<AdvisorGoalMetricResponse> {
    return this.httpClient
      .get<AdvisorGoalMetricResponse>(this.baseUrl + "AdvisorGoalMetrics", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { Date: date, ProjectId: projectId },
      })
      .pipe(
        tap((res) => {
          this._advisorGoalMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  ProductYearSalesMetric(
    year: number,
    projectId: number
  ): Observable<ProductYearSalesMetricResponse> {
    return this.httpClient
      .get<ProductYearSalesMetricResponse>(this.baseUrl + "ProductYearSales", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { Year: year, ProjectId: projectId },
      })
      .pipe(
        tap((res) => {
          this._productYearSalesMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  CategoryTotalSalesMetric(
    startDate: string,
    endDate: string,
    projectId: number,
    step: number
  ): Observable<CategorySalesMetricResponse> {
    return this.httpClient
      .get<CategorySalesMetricResponse>(
        this.baseUrl + "CategoryTotalSalesMetrics",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: {
            StartDate: startDate,
            EndDate: endDate,
            ProjectId: projectId,
            OpportunityStepId: step,
          },
        }
      )
      .pipe(
        tap((res) => {
          this._categorySalesMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  CategoryNumProductSold(
    startDate: string,
    endDate: string,
    projectId: number,
    step: number
  ): Observable<CategorySalesMetricResponse> {
    return this.httpClient
      .get<CategorySalesMetricResponse>(
        this.baseUrl + "CategoryNumProductSold",
        {
          headers: this.httpHeaders.getHeadersPrivate(),
          params: {
            StartDate: startDate,
            EndDate: endDate,
            ProjectId: projectId,
            OpportunityStepId: step,
          },
        }
      )
      .pipe(
        tap((res) => {
          this._categoryNumProductSoldMetric.next(res.data);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );
  }

  GlobalGoalMetric(ProjectId: number): Observable<GoalMetricsResponse> {
    const response = this.httpClient
      .get<GoalMetricsResponse>(this.baseUrl + "GlobalGoalMetric", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ProjectId },
      })
      .pipe(
        tap((resp) => {
          this._goalMetricProject.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }

  SalesPerMonthMetric(ProjectId: number): Observable<ApiResponseData<{ salesPerMonth: SalesPerMonthMetric[]; salesPerWeek: SalesPerMonthWeekMetric[]}>> {
    const response = this.httpClient
      .get<ApiResponseData<{ salesPerMonth: SalesPerMonthMetric[]; salesPerWeek: SalesPerMonthWeekMetric[]}>>(this.baseUrl + "SalesPerMonth", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ProjectId },
      })
      .pipe(
        tap((resp) => {
          this._salesPerMonthMetric.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }

  ClientPerProject(): Observable<ApiResponseData<ClientsPerProject[]>> {
    const response = this.httpClient
      .get<ApiResponseData<ClientsPerProject[]>>(this.baseUrl + "ClientsPerProject", {
        headers: this.httpHeaders.getHeadersPrivate(),
      })
      .pipe(
        tap((resp) => {
          this._clientsPerProject.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }


  TotalCommissionKPI(projectId: number): Observable<ApiResponseData<ICommissionCenter>> {
    const response = this.httpClient
      .get<ApiResponseData<ICommissionCenter>>(this.baseUrl + "TotalCommissionKPI", {
        headers: this.httpHeaders.getHeadersPrivate(),
        params: { ProjectId: projectId },
      })
      .pipe(
        tap((resp) => {
          this._commissionCenter.next(resp);
        }),
        catchError((err) => {
          return throwError(() => err as HttpErrorResponse);
        })
      );

    return response;
  }

}
