<form class="flex flex-col space-y-2" [formGroup]="settingsForm" (ngSubmit)="saveChanges()">
  <h1 class="font-bold text-xl">Configuracion de usuarios</h1>
  <hr class="border-2 border-slate-100 w-full" />
  <div class="flex flex-col border-2 p-2 rounded-md">
    <h2 class="text-lg font-semibold">Alerta de longevidad maxima de oportunidades</h2>
    <p>Enviar una alerta a los asesores cuando se supere el número máximo de meses sin actividad en sus oportunidades.</p>
    <div class="flex items-center space-x-2" *ngIf="$settings | async as data; else loadingSettings">
      <mat-slider class="w-full" min="1" max="6" formControlName="longOpportunityMonths"></mat-slider>
      <span class="rounded-full p-2 flex justify-center items-center shrink-0">
        {{ settingsForm.controls.longOpportunityMonths.value }}
        {{ settingsForm.controls.longOpportunityMonths.value === 1 ? "mes" : "meses" }}
      </span>
    </div>
  </div>
  <div class="flex justify-end items-center space-x-2">
    <button mat-button type="button" (click)="matDialog.closeAll()">
      <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
      <span class="ml-2">Cancelar</span>
    </button>
    <button class="bg-green-500 text-white" mat-button type="submit" [disabled]="settingsForm.invalid">
      <mat-icon class="text-white" [svgIcon]="'mat_outline:save'"></mat-icon>
      <span class="ml-2">Guardar</span>
    </button>
  </div>
</form>
<ng-template #loadingSettings>
  <div class="text-center">
    <span>Cargando configuraciones...</span>
  </div>
</ng-template>
