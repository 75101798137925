import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import {
  ReceiptDetailComponent,
  ReceiptModalComponent,
  ReceiptModalContent,
  ReceiptModifiedDetailComponent,
} from "#root/modules/admin/crm/payment-orders/pages/details/pages/content/components/payment-order-payment-receipts/dialogs/receipt-modal/receipt-modal.component";
import { LoadingDialogComponent } from "#root/shared/components/loading-dialog/loading-dialog.componente";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MarkdownModule } from "ngx-markdown";
import { ProductsDialogComponent } from "./components/products-dialog/products-dialog.component";
import { ReceiptComponent, ReceiptContentComponent } from "./components/receipt/receipt.component";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { VersionChangelogComponent } from "./components/version-changelog/version-changelog.component";
import { BasicObservationDialogComponent } from "./components/basic-observation-dialog/basic-observation-dialog.component";
import { StateAccountPaymentsComponent } from "#root/modules/admin/crm/payment-orders/pages/details/components/print-state-account-payments/components/state-account/state-account.component";
import { LazyLoadedParentComponent } from "./components/lazy-loaded-parent/lazy-loaded-parent.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MarkdownModule.forChild(),
    MatPaginatorModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
  ],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, UploadFileComponent, ReceiptDetailComponent, ReceiptModalComponent, ReceiptModalContent , ReceiptContentComponent, BasicObservationDialogComponent, StateAccountPaymentsComponent, LazyLoadedParentComponent], 
  declarations: [
    ProductsDialogComponent,
    VersionChangelogComponent,
    ReceiptComponent,
    LoadingDialogComponent,
    UploadFileComponent,
    ReceiptDetailComponent,
    ReceiptModalComponent,
    ReceiptModifiedDetailComponent,
    ReceiptModalContent,
    ReceiptContentComponent,
    BasicObservationDialogComponent,
    StateAccountPaymentsComponent,
    LazyLoadedParentComponent
  ],
})
export class SharedModule {}
