import { LoadingDialogComponent } from "#root/shared/components/loading-dialog/loading-dialog.componente";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { finalize, Observable } from "rxjs";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dialogRef: MatDialogRef<LoadingDialogComponent, unknown> | null = null;

    // Verificar si el encabezado "ShowModal" está presente y es "true"
    const showModal = req.headers.get("ShowModal") === "true";

    if (showModal && (req.method === "POST" || req.method === "PUT" || req.method === "DELETE" || req.url.includes("Sap/GetCurrencyRate"))) {
      if (req.url.includes("BlobStorage/Upload")) return next.handle(req);

      dialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true,
        hasBackdrop: false,
        width: "100vw",
        maxWidth: "100vw",
        height: "100%",
        panelClass: "dialog-no-background",
        enterAnimationDuration: "0s",
        exitAnimationDuration: "1s",
        data: {
          label: req.url.includes("Sap/GetCurrencyRate") ? "Obteniendo tasa de cambio" : null,
        },
      });
    }

    return next.handle(req).pipe(
      finalize(() => {
        dialogRef?.close();
      })
    );
  }
}
