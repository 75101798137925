import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';

import { UserComponent } from '#root/core/layout/common/user/user.component';
import { SharedModule } from '#root/shared/shared.module';

import { SettingsUserComponent } from './settings-user/settings-user.component';

@NgModule({
    declarations: [
        UserComponent,
        SettingsUserComponent
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        MatSliderModule
    ],
    exports: [
        UserComponent
    ]
})
export class UserModule {
}
