import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeadersHttpService {
  public getHeadersPublic(options: { showModal?: boolean } = { showModal: true }) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'ShowModal': options.showModal.toString()
    });
  }
  public getHeadersPrivate(options: { showModal?: boolean } = { showModal: true }) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atob(localStorage.getItem('accessToken'))}`,
      'ShowModal': options.showModal.toString()
    });
  }
  public getHeadersFormDataPrivate(options: { showModal?: boolean } = { showModal: true }) {
    return new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      'Authorization': `Bearer ${atob(localStorage.getItem('accessToken'))}`,
      'ShowModal': options.showModal.toString()
    });
  }
}